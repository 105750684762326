// @flow
import * as React from 'react'

import type { RelatedListItemViewModel } from '../../types/RelatedListItemViewModel'
import type { AreaTypes } from '../../types/enums/AreaTypes'

import Ribbon from '../../components/Ribbon/Ribbon'
import ListGroup from '../../components/ListGroup/ListGroup'
import ListCard from '../../components/ListCard/ListCard'

export type Props = {
  title?: string,
  items?: Array<RelatedListItemViewModel>,
  area?: AreaTypes,
  addTopPadding?: boolean,
}

type State = {}

class RelatedContentList extends React.Component<Props, State> {
  static displayName = 'RelatedContentList'
  static defaultProps = {}

  renderCard = (item: RelatedListItemViewModel, index: number) => {
    const key = item && item.title ? `${item.title}-${index}` : `_-${index}`
    return <ListCard {...item} key={key} />
  }

  render() {
    const { title, items, addTopPadding } = this.props

    if (!items || !items.length) return null

    return (
      <Ribbon clearChildMargin paddingTop={addTopPadding ? 50 : 0}>
        <ListGroup title={title} itemsPerColumn={3}>
          {items && items.map(this.renderCard)}
        </ListGroup>
      </Ribbon>
    )
  }
}

export default RelatedContentList
