// @flow
import * as React from 'react'
import type { AreaTypes } from '../../types/enums/AreaTypes'
import Ribbon from '../../components/Ribbon/Ribbon'
import type { VideoCardViewModel } from '../../types/VideoCardViewModel'
import VideoCard from '../../components/VideoCard/VideoCard'
import styled from 'styled-components'
export type Props = {
  items?: Array<VideoCardViewModel>,
  area?: AreaTypes,
  addTopPadding?: boolean,
}

const StyledList = styled.ul`
  display: grid;

  padding: 0;
  gap: 40px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`

class VideoCardList extends React.Component<Props> {
  static displayName = 'VideoCardList'
  static defaultProps = {}

  renderCard = (item: VideoCardViewModel, index: number) => {
    const key = item && item.title ? `${item.title}-${index}` : `_-${index}`

    return <VideoCard {...item} key={key} />
  }

  render() {
    const { items, addTopPadding } = this.props

    if (!items || !items.length) return null

    return (
      <Ribbon clearChildMargin paddingTop={addTopPadding ? 50 : 0}>
        <StyledList>{items && items.map(this.renderCard)}</StyledList>
      </Ribbon>
    )
  }
}

export default VideoCardList
